export function WebAPITester(props) {
	return (
		<center>
			<br /><br /><br /><br /><br /><br />
			Web API Tester is a windows tool to test the Web REST API.<br /> It is a mimick of Swagger. It is a free download and requires .NET Framework 4.7. <br />
			Click this file to download	<a href="https://algosmithcomputing.com/WebAPITester/WebAPITester.zip" alt="">WebAPITester.zip</a> 
			<br /><br />
			<a href="https://algosmithcomputing.com/WebAPITester/WebAPITester.zip" alt=""><img src="WebAPITester.png" width="100" height="100" alt="Web API Tester (Web REST API Tester)" /></a>
			<br /><br />			
			<p >
			<font size="4" color="red">Dislaimer:The tool has been tested and safe numerous times. You may use it at your own risk.</font>
			</p>
			
		</center>
	);
}
