import logo from './logo.svg';
import {createContext, useContext, useRef, React} from "react";


export const MyContext = createContext();

export function Footer() {
	const dateObj  = new Date();
	let lYear = dateObj.getFullYear();
	
	return (		
		<center>
			<div style={{textAlign:"center"}}><img src={logo} className="cls_builtby" alt="logo" /> built with ReactJS</div>				
			© 2000-{lYear} Algosmith Computing. All Rights Reserved.
		</center>		
	);
}

/*
export function HtmlBody (props) {
		
	if (props.webObj.pageName === "about" ) {
		return (
			<About/>
		);
	} else if (props.webObj.pageName === "services" ) {
		return (
			<Services/>
		);
	} else if (props.webObj.pageName === "portfolio" ) {
		return (
			<Portfolio />
		);
	} else if (props.webObj.pageName === "testimonials" ) {
		return (
			<Testimonial />
		);
	} else if (props.webObj.pageName === "contact" ) {
		return (
			<Contact isSubmit={0} />
		);
	} else if (props.webObj.pageName === "home" ){
		return ( 
			<Home landingBase={0}/>
		);
	} else {		
		if (props.featureID === 10) {
			return (
				<StockMP inType={0}/>
			);
		} else if (props.featureID === 11) {
			return (
				<CryptoMP inType={0}/>
			);
		} else {
			return (
				<CryptoCalculator />
			);
		}	
	}
}
*/