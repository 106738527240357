import { useState, React } from "react";


export function Contact(props) {
    const [submitToggle, toggleSwith] = useState(props.isSubmit);

    console.log("You reach contact " + submitToggle);
    const processForm = async function (event) {
        // Prevent the browser from reloading the page
        event.preventDefault();

        // Read the form data
        const formTarget = event.target;
        const formData = new FormData(formTarget);
        console.log(formData);
        const formJson = Object.fromEntries(formData.entries());
        console.log(formJson);
        /*
        let fetchData = {
                  method: "post",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(formJson)
        };
        */
        //let responseObj = await fetch("http://localhost/MyStock/ProcessEmail.php", fetchData);
        let stringifiedFormJSon = JSON.stringify(formJson);
        let responseObj = await fetch("ProcessEmail.php?package=" + stringifiedFormJSon);
        let responseText = await responseObj.text();

        if (responseObj.ok) {
            // Handle success response
            console.log(responseText);
            toggleSwith(1);
        } else {
            // Handle error response
            console.log("Fail");
        }
    };


    if (submitToggle === 0) {
        return (

            <div className="cls_form"
                style={{
                    backgroundImage: "url(IMG-20240511-WA0029.jpg)",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat"
                }}>
                <br /><br /><br /><br />
                <form method="post" onSubmit={processForm}>
                    <div className="cls_formRow">
                        <div id="id_name" className="cls_label">
                            Name*:
                        </div>
                        <div className="cls_field">
                            <input type="text" id="id_fullName" name="fullName" required />
                        </div>
                    </div>
                    <div className="cls_formRow">
                        <div id="id_email" className="cls_label">
                            Email*:
                        </div>
                        <div className="cls_field">
                            <input type="text" id="id_email" name="email" required />
                        </div>
                    </div>
                    <div className="cls_formRow">
                        <div id="id_email" className="cls_label">
                            Phone*:
                        </div>
                        <div className="cls_field">
                            <input type="text" id="id_phone" name="phone" required />
                        </div>
                    </div>
                    <div className="cls_formRow">
                        <div id="id_message" className="cls_label">
                            Message*:
                        </div>
                        <div className="cls_field">
                            <textarea type="text" id="id_message" name="message" required></textarea>
                        </div>
                    </div>
                    <div className="cls_formRow">
                        <div className="cls_label">
                        </div>
                        <div className="cls_field">
                            <button type="submit" className="cls_submit">
                                SUBMIT
                            </button>

                        </div>
                    </div>
                </form><br /><br /><br />
            </div>

        );
    } else {
        return (
            <div className="cls_form">
                <center><br /><br /><br /><br />
                    <b>We will get back to you as soon as possible!!</b>
                    <br /><br /><br />
                    <img src="thankyou.gif" width="100%" height="100%" alt="Thank you!!" />
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /></center>
            </div>
        );
    }
}
