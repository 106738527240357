import {createContext, useContext, useRef, useState, useEffect, React} from "react";


export const GlobalMenuID = createContext(0);


export const flashAds = [
	{adText: "Data-driven business decisions and strategies"
	}, {
			backgroundColor:"#000000",
			opacity:"0.7",
			position:"relative",
			top:"5%",
			left:"2%",
			width:"90%",
			height:"600px",
			paddingLeft:"30px",
			borderRadius: "8px",
			opacity: 0.85
		
	},
	{adText: "Do you need to improve your corporate business infrastructure?"
	}, {
			backgroundColor:"#000000",
			opacity:"0.7",
			position:"relative",
			top:"5%",
			left:"2%",
			width:"90%",
			height:"600px",
			paddingLeft:"30px",
			borderRadius: "8px",
			opacity: 0.85
		
	},
	{adText: "Are you wondering how your company performs?"
	}, {
			backgroundColor:"#000000",
			opacity:"0.7",
			position:"relative",
			top:"5%",
			left:"2%",
			width:"90%",
			height:"600px",
			paddingLeft:"30px",
			borderRadius: "8px",
			opacity: 0.85
		
	}
];

export const menuOption = [
	{Id:0,
	 path: "/",
	 pageName: "home", 
	 optionDisplay: "Home"
	},
	{Id:1,
	 path: "/about",
	 pageName: "about", 
	 optionDisplay: "About"
	},
	{Id:2,
	 path: "/services",
	 pageName: "services", 
	 optionDisplay: "Services"
	},	
	{Id:3,
	 path: "/contact",
	 pageName: "contact", 
	 optionDisplay: "Contact"
	}
];


export const menuIcon = [
	{Id: 0,
	 iconName: "menu_on.png",
	 width: 40,
	 height: 40
	}, {
	 Id: 1,
	 iconName: "menu_off.png",
	 width: 40,
	 height: 40
	}
];


export const services = [
	{	serviceID: 0,
		serviceName: "Custom Software Development",
		serviceDetail: "We build and maintain all types of either web-based, non web-based, or mobile applications either front-end, back-end, or full stack.",
		serviceStyle: {
			display: "block", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "softwaredev.png"
	},	
	{	serviceID: 1, serviceName: "Machine Learning and Data Science.",
		serviceDetail: "We do ML-based projects and data science for model predictions and helping decision making using historical data.",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "NNDataScience.png"
	},	
	{
		serviceID: 2,
		serviceName: "Database Projects",
		serviceDetail: "For the system level, we do: Installation, Setting backup/recovery system, Activity monitoring, System tuning. For programming scope, we help create: Table designs, Simple-to-complex Queries for Views, Store procedures, User-defined functions for Reporting, Business analysis, Business Intelligence, etc. ",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "DataWarehouse.jpg"
	},	
	{
		serviceID: 3,
		serviceName: "Business Data Analysis",
		serviceDetail: "We help transform raw data into actionable insights that drive strategic decision-making within your business",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "DA4DecisionMaker.jpg"
	},
	{
		serviceID: 4,
		serviceName: "Digital Business Data Analysis",
		serviceDetail: "Provide thorough data analysis to optimize the product competitiveness",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "DA4OptmizeProduct.png"
	},
	{
		serviceID: 5,
		serviceName: "Computer Network and Cyber Security",
		serviceDetail: "Help Setup network and security to protect your business",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "NetworkCyberSecurity.png"
	},
	{
		serviceID: 6,
		serviceName: "Internet Marketing and SEO.",
		serviceDetail: "We help business using the internet technology to promote product, service, brand name, and to increase your website rank to reach your ultimate marketing objective",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "MarketingSEO.png"
	},
	{
		serviceID: 7,
		serviceName: "Disaster Recovery (DR)",
		serviceDetail: "We help setup best practice of DR starting from the plan to prepare possibilies of types of disasters to secure the business continuity with minimum downtime, such as: Emergency Power system, Server cloning/load balancing, Database system replication, Data backup/recovery system, Network/firewall configuration backup, etc.",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "DisasterRecovery.png"
	},
	{
		serviceID: 8,
		serviceName: "Information Technology, Systems Services, and Procurement.",
		serviceDetail: "We help all levels of IT services: Hardware and software supplies, operating system including application installing, network topology design, server and network traffic monitoring, and procuring IT infrastructures to keep business running.",
		serviceStyle: {
			display: "none", left:"5%", top: "5%", width: "75%", height: "auto", position: "relative", backgroundColor: "#f7f5f0", borderRadius: "50px 15px", padding: "20px", border: "1px solid #4CAF50", zIndex: "1", color: "black", opacity: "0.85"
		},
		imageName: "ITSolution.png"
	}
];


export const featureapps = [
	{featureAppName: "Stock Prediction",
	 featureID: 10,
	 path: "stock",
	},
	{featureAppName: "Crypto Prediction",
	 featureID: 11,
	 path: "crypto"
	},
	{featureAppName: "Crypto Calculator",
	 featureID: 12,
	 path: "calculator"
	},
	{featureAppName: "Free Web API Tester",
	 featureID: 13,
	 path: "webapitester"
	}
];


export const portfolioList = [
	{imageName: "mgear.png",
	 altDisplay: "This is what we have done"
	},
	{imageName: "gymboree.png",
	 altDisplay: "This is what we have done"
	},
	{imageName: "miamidolphin.png",
	 altDisplay: "This is what we have done"
	}
];