import { React } from "react";


export function Home(props) {
    return (
        <center>
            <img src="home.jpg" className="cls_imageFlash" alt="Home" />
        </center>
    );
}
