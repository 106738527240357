import { useState, useEffect, React } from "react";
import { services } from "./UserData.js";


export function Services() {
    const [indexCount, setIndexCount] = useState(0);
    const [imgDisplay, setImgDisplay] = useState("none");

    const serviceTotal = services.length;
    
    const serviceList = services.map(
        serviceList => <div>
            <div id={serviceList.serviceID} style={serviceList.serviceStyle}>
                <div style={{ display: "flex" }}>
                    <div>
                        <img src={serviceList.imageName} className="cls_serviceImage" alt={serviceList.serviceName } />
                    </div>
                    <div style={{ padding: "20px" }}>
                        <img src="check.png" width="20" height="20" alt="" /> {serviceList.serviceName}
                        <br /><br />                        
                        {serviceList.serviceDetail}
                        
                    </div>

                </div>
            </div>
        </div>
    );

    function indexCounter() {
        for (var i = 0; i < serviceTotal; i++) {
            if (document.getElementById(i)) {
                document.getElementById(i).style.display = "none";
            }
        }

        if (document.getElementById(indexCount)) {
            document.getElementById(indexCount).style.display = "block";
        }
        setIndexCount((indexCount < serviceTotal - 1) ? (indexCount + 1) : 0);
    }

    function animationFunct() {
        setTimeout(indexCounter, 5000);
    }

    useEffect(animationFunct);

    return (
        <center><br /><br /><br /><br /><br />
            <div className="cls_serviceList">
                <h2><nobr>We provide services:</nobr></h2>
                <div>
                    {serviceList}
                </div>
                <br /><br /><br />
            </div>
        </center>
    );
}

export function Service_TEMP() {
    const [indexCount, setIndexCount] = useState(0);

    const cls_innerService = {
        backgroundColor: "#000000",
        opacity: "0.7",
        position: "relative",
        top: "5%",
        left: "2%",
        width: "90%",
        height: "100%",
        paddingLeft: "30px",
        borderRadius: "8px",
        fontFace: "10px"
    };

    const serviceTotal = services.length;
    const serviceList = services.map(
        serviceList => <> <div id={serviceList.serviceID} style={serviceList.serviceStyle}>{serviceList.serviceDetail}</div><div><img src="check.png" width="15" height="15" alt="" /> {serviceList.serviceName}</div><br /></>
    );

    function indexCounter() {
        for (var i = 0; i < serviceTotal; i++) {
            document.getElementById(i).style.display = "none";
        }

        document.getElementById(indexCount).style.display = "block";

        setIndexCount((indexCount < serviceTotal - 1) ? (indexCount + 1) : 0);
    }

    function animationFunct() {
        setTimeout(indexCounter, 10000);
    }

    useEffect(animationFunct);

    //console.log("====> " + popUpID);
    return (
        <center><br /><br /><br /><br /><br />
            <div className="cls_serviceList" style={{ backgroundImage: "url(IMG-20240511-WA0017.jpg)" }}>
                <div style={cls_innerService}>
                    <br />
                    <h2><nobr>We provide services:</nobr></h2>
                    <br />
                    {serviceList}
                    <br />
                    <br /><br />
                </div>
            </div>
        </center>
    );
}
