import { React } from "react";

export function About() {

    return (
        <center>
            <div className="cls_about">

                <h2>About Us</h2>
                <p>Welcome to Algosmith Computing, your trusted partner in IT solutions.</p>

                <p>We've been providing custom software development and consulting services to businesses of all sizes since Year 2000. Our team has a proven track record of excellence, having involved in major projects for renowned companies such as:</p>

                <li> Proshop of MiamiDolphins.com</li>
                <li> Proshop of Si.com (sports illustrated)</li>
                <li> Proshop of Wwe.com (World Wrestling Federation)</li>
                <li> Broker's Price Opinion (formerly AsgBPO.com)</li>
                <li> LillianVernon.com</li>
                <li> Gymboree.com</li>
                <li> Mgear.com</li>
                <li> Monsanto.com</li>
                <li> among others. </li>

                <p>For each project, we've brought our expertise, dedication, and innovative approach to the table, ensuring that our clients receive top-tier service and results. Our extensive experience in handling high-profile assignments speaks to our capability and commitment to delivering solutions that meet and exceed expectations.</p>

                <p>With years of industry experience and a team of seasoned professionals, we are committed to helping our clients leverage technology to drive growth, streamline operations, and achieve their business objectives.</p>

                <p><b>Our Mission</b></p>

                <p>Our mission is to deliver innovative and tailored IT solutions that empower our clients to thrive in today's dynamic business environment. We strive to understand our clients' unique challenges and goals, and we work collaboratively to develop customized strategies and solutions that meet their specific needs.</p>

                <p><b>What Sets Us Apart</b></p>
                <blockquote>
                    <p> <b>Expertise:</b> Our team consists of highly skilled and experienced IT professionals who are passionate about delivering exceptional results.</p>
                    <p> <b>Client-Centric Approach:</b> We prioritize building long-term relationships with our clients and are dedicated to providing personalized service and support.</p>
                    <p> <b>Innovation:</b> We stay abreast of the latest technological advancements and trends to ensure that our clients benefit from cutting-edge solutions.</p>
                    <p> <b>Value:</b> We are committed to delivering value-driven solutions that help our clients achieve tangible business outcomes and maximize their return on investment.</p>
                </blockquote>
                <br /><br /><br />
            </div>
        </center>
    );
}
