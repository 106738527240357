import './App.css';
import { useContext, useState } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { Footer } from "./UserWebsite";
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Services } from './pages/Services';
import { Portfolio } from './pages/Portfolio';
import { Testimonial } from './pages/Testimonial';
import { Contact } from './pages/Contact';
import { Nopage } from './pages/Nopage';
import { CryptoMP } from './pages/CryptoMP';
import { WebAPITester } from "./pages/Download";
import { ModelPrediction, StockMP, CryptoCalculator } from "./pages/ModelPrediction";
import { menuOption, featureapps, GlobalMenuID } from "./pages/UserData";




export default function App() {
    const [webObj, setWebObj] = useState(menuOption[0]);
    //const [optionID, setOptionID] = useState(0);
    const [toggleFlag, setToggelFlag] = useState(0);
    const [featureChoice, setFeatureChoice] = useState(0);
    const iconMenu = ["menu_on.png", "menu_off.png"];


    const getGlobalMenuID = useContext(GlobalMenuID);

    const theOption = menuOption.map(
        theOption => <div className="cls_menuOptionList"><Link to={theOption.path} className="cls_menuOption"  onClick={() => switchPage(theOption.Id)}><img src="arrow.png" width="20" height="20" alt="" /> {theOption.optionDisplay}</Link></div>
    );

    const featureSwitch = function (inVal) {
        setWebObj(inVal);        
        //setOptionID(inObjIndex);
        //toggleMenu();
        //localStorage.setItem("LocalStorage", inObjIndex);
        setFeatureChoice(inVal);
        localStorage.setItem("LocalStorage", inVal);
    };


    const featureappList = featureapps.map(
        featureappList => <div><img src="check.png" width="25" height="25" alt="" /><Link to={featureappList.path} className="cls_menuOption" onClick={() => { featureSwitch(featureappList.featureID); }}>{featureappList.featureAppName}</Link></div>
    );


    const toggleMenu = function () {
        setToggelFlag(toggleFlag ^ 1);
    };

    const switchPage = function (inObjIndex) {
        setWebObj(menuOption[inObjIndex]);
        
        //setOptionID(inObjIndex);
        toggleMenu();
        localStorage.setItem("LocalStorage", inObjIndex);
    };


    const resetToggle = function () {
        setToggelFlag(0);
    };

    const MainMenu = function() {
        return (
            <>
                <div className="cls_leftHeader">
                    <button onClick={toggleMenu}>
                        <img src={iconMenu[toggleFlag]} className="cls_iconImage" alt=""/>
                    </button>
                </div>
                <div className="cls_menulistHolder">
                    {(toggleFlag) ? theOption : null}
                </div>
            </>
        );
    };

    const FeatureAppMenu = function () {
        return (
            <div className="cls_homeService">
                <h3>Feature Apps:</h3>
                <b>{featureappList}</b>
                <h3>Contact us:</h3>

                <Link to="/contact" ><img src="email.png" width="200" height="50" alt="" /></Link><br /><br />
                <a href="https://api.whatsapp.com/send?phone=12039338071" title="(203) 933-8071" target="_self"><img src="whatsapp.png" width="200" height="50" /></a>
                <br /><br /><br />
            </div>
        );
    };


    console.log("From App, menuid= " + getGlobalMenuID);
    return (
        <center>
            <div className="App">
                <BrowserRouter>
                    <div className="App-header">                    
                        <MainMenu />
                        <div className="cls_centerHeader">
                            <a href="/"><img src="algosmith_logo.png" width="300" height="100" alt="" /></a>
                        </div>
                        <div className="class_rightHeader">
                            <font style={{ textShadow: "2px 2px #000000" }}>Algosmith <br />Computing </font>
                        </div>
                    </div>
                    <div className="App-body" onClick={resetToggle}>                    
                        <Routes>
                            <Route index element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/contact" element={<Contact isSubmit={0}/>} />
                        
                            <Route path="/stock" element={<StockMP />} />
                            <Route path="/crypto" element={<CryptoMP />} />
                            <Route path="/calculator" element={<CryptoCalculator />} />
                            <Route path="/webapitester" element={<WebAPITester />} />                            
                            <Route path="/*" element={<Nopage />} />
                        </Routes>
                        <br /><br /><br /><br />
                    </div>
                    <FeatureAppMenu />
                </BrowserRouter>

                <div className="App-footer">
                    <Footer />
                </div>
                <br /><br /><br /><br />
                
            </div>

            <div className="cls_bottom"><a href="https://api.whatsapp.com/send?phone=12039338071" title="(203) 933-8071" target="_self"><img src="whatsapp.png" width="250" height="50" /></a></div>
        </center>
    );
}

